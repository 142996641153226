import { Box } from "@mui/material";
export const Privacy = () => {
  return (
    <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "left",
          flexDirection: "column",
          width: '1000px',
          padding: '50px 20px 0 20px',
        }}
      >
        <h1>Privacy Policy</h1>
        
        <p>
          Last updated: November 30, 2023 <br />
          This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your
          information when You use the Service and tells You about Your privacy rights and how the law protects
          You.<br />
          We use Your Personal data to provide and improve the Service. By using the Service, You agree to the
          collection and use of information in accordance with this Privacy Policy
        </p>
        <i style={{color: "blue"}}>Interpretation and Definitions</i>

        <h3>Interpretation</h3>
        <p>
          The words of which the initial letter is capitalized have meanings defined under the following conditions.
          The following definitions shall have the same meaning regardless of whether they appear in singular or in
          plural.
        </p>
        <h3>Definitions</h3>
        <p>
          For the purposes of this Privacy Policy: <br />
          <b>Account</b> means a unique account created for You to access our Service or parts of our Service.<br />
          <b>Business</b>, for the purpose of the CCPA (California Consumer Privacy Act), refers to the Company as the
          legal entity that collects Consumers' personal information and determines the purposes and means of the
          processing of Consumers' personal information, or on behalf of which such information is collected and
          that alone, or jointly with others, determines the purposes and means of the processing of consumers'
          personal information, that does business in the State of California. <br />
          <b>Company</b> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to The
          Fair Insurance. <br />
          <b>Consumer</b>, for the purpose of the CCPA (California Consumer Privacy Act), means a natural person
          who is a California resident. A resident, as defined in the law, includes (1) every individual who is in the
          USA for other than a temporary or transitory purpose, and (2) every individual who is domiciled in the
          USA who is outside the USA for a temporary or transitory purpose. <br />
          <b>Cookies</b> are small files that are placed on Your computer, mobile device or any other device by a website,
          containing the details of Your browsing history on that website among its many uses. <br />
          Country refers to: Israel 
          <b>Device</b> means any device that can access the Service such as a computer, a cellphone or a digital tablet. <br />
          <b>Do Not Track (DNT)</b> is a concept that has been promoted by US regulatory authorities, in particular the
          U.S. Federal Trade Commission (FTC), for the Internet industry to develop and implement a mechanism
          for allowing internet users to control the tracking of their online activities across websites.<br />
          <b>Personal Data</b> is any information that relates to an identified or identifiable individual.
          For the purposes of the CCPA, Personal Data means any information that identifies, relates to, describes
          or is capable of being associated with, or could reasonably be linked, directly or indirectly, with You.<br />
          <b>Sale</b>, for the purpose of the CCPA (California Consumer Privacy Act), means selling, renting, releasing,
          disclosing, disseminating, making available, transferring, or otherwise communicating orally, in writing,
          or by electronic or other means, a Consumer's Personal information to another business or a third party for
          monetary or other valuable consideration.<br />
          <b>Service</b> refers to the Website.<br />
          <b>Service Provider</b> means any natural or legal person who processes the data on behalf of the Company. It
          refers to third-party companies or individuals employed by the Company to facilitate the Service, to
          provide the Service on behalf of the Company, to perform services related to the Service or to assist the
          Company in analyzing how the Service is used.<br />
          <b>Third-party</b> Social Media Service refers to any website or any social network website through which a
          User can log in or create an account to use the Service.<br />
          <b>Usage Data</b> refers to data collected automatically, either generated by the use of the Service or from the
          Service infrastructure itself (for example, the duration of a page visit).<br />
          <b>Website</b> refers to Thefairinsurance, accessible from www.T.com<br />
          <b>You</b> means the individual accessing or using the Service, or the company, or other legal entity on behalf
          of which such individual is accessing or using the Service, as applicable.<br />
        </p>
        <i style={{color: "blue"}}>Collecting and Using Your Personal Data</i>
        <h3>Types of Data Collected</h3>
        <p>
          <b>Personal Data</b><br />
          While using Our Service, We may ask You to provide Us with certain personally identifiable information
          that can be used to contact or identify You. Personally identifiable information may include, but is not
          limited to:<br />
          <ul>
            <li>Email address</li>
            <li>First name and last name</li>
            <li>Phone number</li>
            <li>Usage Data</li>
          </ul>
          <b>Usage Data</b><br />
          Usage Data is collected automatically when using the Service.<br />
          Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address),
          browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the
          time spent on those pages, unique device identifiers and other diagnostic data.<br />
          When You access the Service by or through a mobile device, We may collect certain information
          automatically, including, but not limited to, the type of mobile device You use, Your mobile device
          unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile
          Internet browser You use, unique device identifiers and other diagnostic data.<br />
          We may also collect information that Your browser sends whenever You visit our Service or when You
          access the Service by or through a mobile device.<br />
          <b>Tracking Technologies and Cookies</b><br />
          We use Cookies and similar tracking technologies to track the activity on Our Service and store certain
          information. Tracking technologies used are beacons, tags, and scripts to collect and track information
          and to improve and analyze Our Service.<br />
          You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However,
          if You do not accept Cookies, You may not be able to use some parts of our Service.<br />
          Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on your personal computer
          or mobile device when You go offline, while Session Cookies are deleted as soon as You close your web
          browser. Learn more about cookies: Cookies: What Do They Do?<br />
          We use both session and persistent Cookies for the purposes set out below:<br />
          <ul>
            <li><b>Necessary / Essential Cookies</b></li>
            <li>Type: Session Cookies</li>
            <li>Administered by: Us</li>
            <li>
              Purpose: These Cookies are essential to provide You with services available through the
              Website and to enable You to use some of its features. They help to authenticate users
              and prevent fraudulent use of user accounts. Without these Cookies, the services that
              You have asked for cannot be provided, and We only use these Cookies to provide You
              with those services
            </li>
            <li><b>Cookies Policy / Notice Acceptance Cookies</b></li>
            <li>Type: Persistent Cookies</li>
            <li>Administered by: Us</li>
            <li>
              Purpose: These Cookies identify if users have accepted the use of cookies on the
              Website.
            </li>
            <li><b>Functionality Cookies</b></li>
            <li>Type: Persistent Cookies</li>
            <li>Administered by: Us</li>
            <li>
              Purpose: These Cookies allow us to remember choices You make when You use the
              Website, such as remembering your login details or language preference. The purpose of
              these Cookies is to provide You with a more personal experience and to avoid You having
              to re-enter your preferences every time You use the Website.
            </li>
            <li><b>Tracking and Performance Cookies</b></li>
            <li>Type: Persistent Cookies</li>
            <li>Administered by: Third-Parties</li>
            <li>
              Purpose: These Cookies are used to track information about traffic to the Website and
              how users use the Website. The information gathered via these Cookies may directly or
              indirectly identify you as an individual visitor. This is because the information collected is
              typically linked to a pseudonymous identifier associated with the device you use to
              access the Website. We may also use these Cookies to test new advertisements, pages,
              features or new functionality of the Website to see how our users react to them.
            </li>
          </ul>
          For more information about the cookies we use and your choices regarding cookies, please visit our
          Cookies Policy or the Cookies section of our Privacy Policy.
          <h3>Use of Your Personal Data</h3>
          The Company may use Personal Data for the following purposes:<br />
          <ul>
            <li>To provide and maintain our Service, including to monitor the usage of our Service.</li>
            <li>
              To manage Your Account: to manage Your registration as a user of the Service. The Personal
              Data You provide can give You access to different functionalities of the Service that are
              available to You as a registered user.
            </li>
            <li>
              For the performance of a contract: the development, compliance and undertaking of the
              purchase contract for the products, items or services You have purchased or of any other
              contract with Us through the Service.
            </li>
            <li>
              To contact You: To contact You by email, telephone calls, SMS, or other equivalent forms of
              electronic communication, such as a mobile application's push notifications regarding
              updates or informative communications related to the functionalities, products or
              contracted services, including the security updates, when necessary or reasonable for their
              implementation.
            </li>
            <li>
              To provide You with news, special offers and general information about other goods,
              services and events which we offer that are similar to those that you have already purchased
              or enquired about unless You have opted not to receive such information.
            </li>
            <li>
              To manage Your requests: To attend and manage Your requests to Us.
            </li>
          </ul>
          We may share your personal information in the following situations: <br />
          <ul>
            <li>
              With Service Providers: We may share Your personal information with Service Providers to
              monitor and analyze the use of our Service, to contact You.
            </li>
            <li>
              For Business partners: When you submit an inquiry or using another other Service provided
              through the Website, you direct Cheaper-Quotes to share information about you or
              provided by you with Insurers and other third parties in our network, which to deliver the
              products and services your request (collectively, “Network Partners”). PLEASE NOTE: The
              Network Partners that interact with you may retain or use your information whether or not
              you use their services. You should contact these Network Partners directly concerning their
              privacy and information sharing practices. Pursuant to the contracts between CheaperQuotes and its Network Partners, if you select a Network Partner through a Cheaper-Quotes
              website, Cheaper-Quotes may also receive information about you from that Network
              Partner including, but not limited to, the amount or disposition of your Insurance. CheaperQuotes may maintain this information to assist with litigation, regulatory request(s), law
              enforcement requests, internal analytics, marketing purposes, and any other legally
              permissible purpose.
            </li>
            <li>
              With Affiliates: We may share Your information with Our affiliates, in which case we will
              require those affiliates to honor this Privacy Policy. Affiliates include Our parent company
              and any other subsidiaries, joint venture partners or other companies that We control or
              that are under common control with Us.
            </li>
            <li>
              With Business partners: We may share Your information with Our business partners to offer
              You certain products, services or promotions.
            </li>
            <li>
              With other users: when You share personal information or otherwise interact in the public
              areas with other users, such information may be viewed by all users and may be publicly
              distributed outside. If You interact with other users or register through a Third-Party Social
              Media Service, Your contacts on the Third-Party Social Media Service may see Your name,
              profile, pictures and description of Your activity. Similarly, other users will be able to view
              descriptions of Your activity, communicate with You and view Your profile.
            </li>
          </ul>
          <h3>Retention of Your Personal Data</h3>
          The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in
          this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our
          legal obligations (for example, if we are required to retain your data to comply with applicable laws),
          resolve disputes, and enforce our legal agreements and policies.<br />
          The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained
          for a shorter period of time, except when this data is used to strengthen the security or to improve the
          functionality of Our Service, or We are legally obligated to retain this data for longer time periods. <br />
          <h3>Transfer of Your Personal Data</h3>
          Your information, including Personal Data, is processed at the Company's operating offices and in any
          other places where the parties involved in the processing are located. It means that this information may
          be transferred to — and maintained on — computers located outside of Your state, province, country or
          other governmental jurisdiction where the data protection laws may differ than those from Your
          jurisdiction.<br />
          Your consent to this Privacy Policy followed by Your submission of such information represents Your
          agreement to that transfer.<br />
          The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in
          accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an
          organization or a country unless there are adequate controls in place including the security of Your data
          and other personal information.<br />
          <h3>Disclosure of Your Personal Data</h3>
          <b>Business Transactions</b>
          If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred.
          We will provide notice before Your Personal Data is transferred and becomes subject to a different
          Privacy Policy.<br />
          <b>Law enforcement</b>
          Under certain circumstances, the Company may be required to disclose Your Personal Data if required to
          do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).<br />
          <b>Other legal requirements</b>
          The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:<br />
          <ul>
            <li>Comply with a legal obligation</li>
            <li>Protect and defend the rights or property of the Company</li>
            <li>Prevent or investigate possible wrongdoing in connection with the Service</li>
            <li>Protect the personal safety of Users of the Service or the public</li>
            <li>Protect against legal liability</li>
          </ul>
          <h3>Security of Your Personal Data</h3>
          The security of Your Personal Data is important to Us, but remember that no method of transmission over
          the Internet, or method of electronic storage is 100% secure. While We strive to use commercially
          acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.<br />
          <i style={{color: 'blue'}}>Detailed Information on the Processing of Your Personal Data</i>
          Service Providers have access to Your Personal Data only to perform their tasks on Our behalf and are
          obligated not to disclose or use it for any other purpose.<br />
          <b>Analytics</b>
          We may use third-party Service providers to monitor and analyze the use of our Service.<br />
          <b>Google Analytics</b>
          Google Analytics is a web analytics service offered by Google that tracks and reports website traffic.
          Google uses the data collected to track and monitor the use of our Service. This data is shared with other
          Google services. Google may use the collected data to contextualize and personalize the ads of its own
          advertising network.<br />
          You can opt-out of having made your activity on the Service available to Google Analytics by installing
          the Google Analytics opt-out browser add-on. The add-on prevents the Google Analytics JavaScript
          (ga.js, analytics.js and dc.js) from sharing information with Google Analytics about visits activity.<br />
          For more information on the privacy practices of Google, please visit the Google Privacy & Terms web
          page: https://policies.google.com/privacy
          <i style={{color: 'blue'}}>CCPA Privacy</i>
          Under this Privacy Policy, and by law if You are a resident of California, You have the following rights:<br />
          <ul>
            <li>
              <b>The right to notice.</b> You must be properly notified which categories of Personal Data are
              being collected and the purposes for which the Personal Data is being used.
            </li>
            <li>
              <b>The right to access / the right to request. </b> The CCPA permits You to request and obtain from
              the Company information regarding the disclosure of Your Personal Data that has been
              collected in the past 12 months by the Company or its subsidiaries to a third-party for the
              third party's direct marketing purposes.
            </li>
            <li>
              <b>The right to say no to the sale of Personal Data.</b> You also have the right to ask the Company
              not to sell Your Personal Data to third parties. You can submit such a request by visiting our
              "Do Not Sell My Personal Information" section or web page.
            </li>
            <li>
              <b>The right to know about Your Personal Data.</b> You have the right to request and obtain from
              the Company information regarding the disclosure of the following:
              <ul>
                <li>The categories of Personal Data collected</li>
                <li>The sources from which the Personal Data was collected</li>
                <li>The business or commercial purpose for collecting or selling the Personal
                  Data</li>
                <li>Categories of third parties with whom We share Personal Data</li>
                <li>The specific pieces of Personal Data we collected about You</li>
              </ul>
            </li>
            <li>
              <b>The right to delete Personal Data.</b> You also have the right to request the deletion of Your
              Personal Data that have been collected in the past 12 months.             
            </li>
            <li>
              <b>The right not to be discriminated against.</b> You have the right not to be discriminated against
              for exercising any of Your Consumer's rights, including by:
              <ul>
                <li>Denying goods or services to You</li>
                <li>Charging different prices or rates for goods or services, including the use of
                  discounts or other benefits or imposing penalties</li>
                <li>Providing a different level or quality of goods or services to You</li>
                <li>Suggesting that You will receive a different price or rate for goods or services
                  or a different level or quality of goods or services.</li>
              </ul>
            </li>
          </ul>
          <h3>Exercising Your CCPA Data Protection Rights</h3>
          In order to exercise any of Your rights under the CCPA, and if you are a California resident, You can
          email or call us or visit our "Do Not Sell My Personal Information" section or web page.<br />
          The Company will disclose and deliver the required information free of charge within 45 days of
          receiving Your verifiable request. The time period to provide the required information may be extended
          once by an additional 45 days when reasonable necessary and with prior notice.<br />
          <h3>Do Not Sell My Personal Information</h3>
          We do not sell personal information. However, the Service Providers we partner with (for example, our
          advertising partners) may use technology on the Service that "sells" personal information as defined by
          the CCPA law.<br />
          If you wish to opt out of the use of your personal information for interest-based advertising purposes and
          these potential sales as defined under CCPA law, you may do so by following the instructions below.
          Please note that any opt out is specific to the browser You use. You may need to opt out on every browser
          that you use.<br />
          <h3>Website</h3>
          You can opt out of receiving ads that are personalized as served by our Service Providers by following
          our instructions presented on the Service:<br />
          <ul>
            <li>From Our "Cookie Consent" notice banner</li>
            <li>Or from Our "CCPA Opt-out" notice banner</li>
            <li>Or from Our "Do Not Sell My Personal Information" notice banner</li>
            <li>Or from Our "Do Not Sell My Personal Information" link</li>
          </ul>
          The opt out will place a cookie on Your computer that is unique to the browser You use to opt out. If you
          change browsers or delete the cookies saved by your browser, you will need to opt out again.
          <h3>Mobile Devices</h3>
          Your mobile device may give you the ability to opt out of the use of information about the apps you use
          in order to serve you ads that are targeted to your interests:<br />
          <ul>
            <li>"Opt out of Interest-Based Ads" or "Opt out of Ads Personalization" on Android devices</li>
            <li>"Limit Ad Tracking" on iOS devices</li>
          </ul>
          You can also stop the collection of location information from Your mobile device by changing the
          preferences on your mobile device.<br />
          <i style={{color: "blue"}}>"Do Not Track" Policy as Required by California Online Privacy Protection Act (CalOPPA)</i>
          Our Service does not respond to Do Not Track signals.<br />
          However, some third party websites do keep track of Your browsing activities. If You are visiting such
          websites, You can set Your preferences in Your web browser to inform websites that You do not want to
          be tracked. You can enable or disable DNT by visiting the preferences or settings page of Your web
          browser.<br />
          <i style={{color: "blue"}}>Your California Privacy Rights (California's Shine the Light law)</i>
          Under California Civil Code Section 1798 (California's Shine the Light law), California residents with an
          established business relationship with us can request information once a year about sharing their Personal
          Data with third parties for the third parties' direct marketing purposes.<br />
          If you'd like to request more information under the California Shine the Light law, and if you are a
          California resident, You can contact Us using the contact information provided below.<br />
          <i style={{color: "blue"}}>California Privacy Rights for Minor Users (California Business and Professions Code Section 22581)</i>
          California Business and Professions Code section 22581 allow California residents under the age of 18
          who are registered users of online sites, services or applications to request and obtain removal of content
          or information they have publicly posted.<br />
          To request removal of such data, and if you are a California resident, You can contact Us using the
          contact information provided below, and include the email address associated with Your account.<br />
          Be aware that Your request does not guarantee complete or comprehensive removal of content or
          information posted online and that the law may not permit or require removal in certain circumstances.
          <i style={{color: "blue"}}>Links to Other Websites</i>
          Our Service may contain links to other websites that are not operated by Us. If You click on a third party
          link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of
          every site You visit.<br />
          We have no control over and assume no responsibility for the content, privacy policies or practices of any
          third party sites or services.<br />
          <i style={{color: "blue"}}>Changes to this Privacy Policy</i>
          We may update our Privacy Policy from time to time. We will notify You of any changes by posting the
          new Privacy Policy on this page.<br />
          We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming
          effective and update the "Last updated" date at the top of this Privacy Policy.<br />
          You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy
          Policy are effective when they are posted on this page.<br />
          <i style={{color: "blue"}}>Contact Us</i>
          If you have any questions about this Privacy Policy, You can contact us:<br />
          By email:<br />
        </p>
  
      </Box>
    </div>
    
  );
  
}